import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.svg';
const HeadWrap = styled.header`
    background-color: #20232a;
    color: #fff;
`;

const Navigation = styled.nav`
    max-width: 1260px;
    display: flex;
    justify-content: space-between;
    margin: 0 90px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    align-items: center;
    > * {
        height: 60px;
    }
    > a {
        color: #61dafb;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        > img {
            height: 24px;
        }
        display:flex;
        align-items: center;
    }
`;

const Links = styled.section`
    > a{
        color: #fff;
        padding: 0 20px;
    }
    display: flex;
    align-items: center;
    font-size: 18px;
`

export default function Header(){
    return(
        <HeadWrap>
            <Navigation>
                <Link to="/">
                    <img src={logo} alt='logo'/>
                    <span> SravanKumar.dev</span>
                </Link>
                <Links>
                    <Link to='/react'> Learn React</Link>
                    <Link to='/'> Tutorial</Link>
                    <Link to='/'> Blog</Link>
                    <a href='https://www.linkedin.com/in/imsravan/'> LinkedIn</a>
                    <a href='https://github.com/sravan2828'> Github</a>
                </Links>
                
            </Navigation>
          
        </HeadWrap>
    );
}