import React from 'react'
import styled from 'styled-components';
import { Button } from 'antd';
import {ShareAltOutlined, CheckOutlined} from '@ant-design/icons'
const Container = styled.div`
    background: #292d34;
    > * {
        padding: 30px 120px;
    }
`;

const FirstSection = styled.div`
    max-width: 50%;
    * {
        color: #fff;
    }
    > p{
        font-size: 1.2rem;
    }
    * a {
        color: #61dafb;
    }
    > button {
        margin-top: 20px;
        background: transparent;
        cursor: pointer;
        padding: 12px;
        border: 1px solid;
    }
`;

const Content = styled.div`
    max-width: 50%;
`;

const CourseInfo = styled.div`
    width: 100%;
    padding: 24px 24px 16px;
    background:#fbfbf8;
    border: 1px solid #dcdacb;
    border-radius: 4px;
    > h2{
        margin-top: 0;
    }
    > div{
        max-width: 45%;
        display: inline-flex;
        color: #3c3b37;
        margin: 5px 20px 5px 0;
        > span{
            margin-top: 3px;
        }
        > p{
            margin: 0;
            margin-left: 15px;
            font-weight: 400;
            line-height: 1.4;
        }
    }
`;
export default function ReactLanding(){
    return(
        <Container>
            <FirstSection>
                <h1>
                    React - The Complete Guide (incl Hooks, React Router, Redux)
                </h1>
                <p>Dive in and learn React.js from scratch! Learn Reactjs, Hooks, Redux, React Routing, Animations, Next.js and way more!</p>
                <span>
                    Created by <a href="https://www.linkedin.com/in/imsravan/" target='_blank' rel="noreferrer">Sravan Kumar</a>
                </span>
                <br/>
                <Button>Share <ShareAltOutlined /></Button>
            </FirstSection>
            <Content>
                <CourseInfo>
                    <h2>
                        What you'll learn
                    </h2>
                    <div>
                    <CheckOutlined /><p> Build powerful, fast, user-friendly and reactive web apps</p>
                    </div>
                    <div>
                    <CheckOutlined /><p> Provide amazing user experiences by leveraging the power of JavaScript with ease</p>
                    </div>
                    <div>
                    <CheckOutlined /> <p> Apply for high-paid jobs or work as a freelancer in one the most-demanded sectors you can find in web dev right now</p>
                    </div>
                    <div>
                    <CheckOutlined /><p> Learn React Hooks & Class-based Components</p>
                    </div>
                    
                </CourseInfo>
            </Content>
        </Container>
    );
}