import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import styled from 'styled-components';

import Header from 'components/Header';
import Landing from 'components/Landing';
import ReactLanding from 'components/ReactLanding';

import './App.css';
function App() {
  return (
    <Router>
        <Header />
        <Switch>
          <Route path='/react'>
            <ReactLanding/>
          </Route>
          <Route path='/'>
            <Landing/>
          </Route>
        </Switch>
    </Router>
   
  );
}

export default App;
